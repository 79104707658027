import { LOCAL_STORAGE_KEY } from './ApiConf';

function logout() {
  window.localStorage.removeItem(LOCAL_STORAGE_KEY);
}

function apiClient(method, endpoint, { body, ...customConfig } = {}) {
  const token = window.localStorage.getItem(LOCAL_STORAGE_KEY);

  const headers = {
    'content-type': 'application/json',
    ...(token && {
      Authorization: `Token ${token}`,
    }),
    ...customConfig.headers,
  };

  const config = {
    method,
    ...customConfig,
    headers,
    ...(body && {
      body: JSON.stringify(body),
    }),
  };

  if (!process.env.REACT_APP_API_URL) {
    throw new Error('API URL not defined. Are the environment variables set?');
  }
  return window
    .fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, config)
    .then(async response => {
      if (response.status === 401) {
        logout();
        // refresh the page for them
        window.location.assign(window.location);
        return null;
      }

      if (response.status === 403) {
        const error = new Error(`Unauthorized to perform this action`);
        error.status = 403;
        throw error;
      }

      if (response.status === 404) {
        const error = new Error(`Not found`);
        error.status = 404;
        throw error;
      }

      if (response.status === 409) {
        const error = new Error(`Conflict`);
        error.status = 409;
        throw error;
      }

      if (response.status === 500) {
        const error = new Error(`Internal server error`);
        error.status = 500;
        throw error;
      }

      let data;
      try {
        // Only resolve the promise if the response was ok. Otherwise, if either response.ok is
        // false or if the json() method raises an exception, fall through the try/catch block and
        // return a rejected promise.
        data = await response.json();
        if (response.ok) {
          return data;
        }
      } catch {
        // Fall through to the reject() call on an exception.
      }
      return Promise.reject(data);
    });
}

export default apiClient;
export { logout };
