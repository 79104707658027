/* eslint-disable no-shadow */
import { useRef, useReducer, useLayoutEffect, useCallback } from 'react';

// prevents problems with unmounted components
function useSafeDispatch(dispatch) {
  const mounted = useRef(false);
  // useLayoutEffect is a version of useEffect that happens
  // after DOM is ready
  useLayoutEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  }, []);
  return useCallback((...args) => (mounted.current ? dispatch(...args) : undefined), [dispatch]);
}

// handles a promise in a safe manner
function useAsync() {
  // Whatever is passed in setState will be merged to the state
  const [{ status, data, error }, setState] = useReducer(
    (state, action) => ({ ...state, ...action }),
    {
      status: 'idle',
      data: null,
      error: null,
    }
  );

  // safe version of setState
  const safeSetState = useSafeDispatch(setState);

  // unwinding the promise
  const run = useCallback(
    promise => {
      if (!promise || !promise.then) {
        throw new Error(
          `The argument passed to useAsync().run must be a promise.\
          Maybe a function that's passed isn't returning anything?`
        );
      }
      safeSetState({ status: 'pending' });
      return promise.then(
        data => {
          safeSetState({ data, status: 'resolved' });
          return data;
        },
        error => {
          safeSetState({ status: 'rejected', error });
          return error;
        }
      );
    },
    [safeSetState]
  );

  const setData = useCallback(data => safeSetState({ data }), [safeSetState]);
  const setError = useCallback(error => safeSetState({ error }), [safeSetState]);

  return {
    // inspired by react-query
    isIdle: status === 'idle',
    isLoading: status === 'pending',
    isError: status === 'rejected',
    isSuccess: status === 'resolved',

    error,
    status,
    data,
    run,

    setData,
    setError,
  };
}

export default useAsync;
