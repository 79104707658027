import React from 'react';
import { css, keyframes } from 'styled-components/macro';
import { FaSpinner } from 'react-icons/fa';

const FullPageSpinner = () => {
  const rotate = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  `;

  return (
    <div
      css={css`
        font-size: 4em;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      <div
        css={css`
          animation: ${rotate} 1s linear infinite;
        `}
      >
        <FaSpinner />
      </div>
    </div>
  );
};

export default FullPageSpinner;
