/* eslint-disable react/jsx-props-no-spreading */
// vendor imports
import React, { useContext, useLayoutEffect } from 'react';

// imports
import useAsync from '../utils/useAsync';
import FullPageSpinner from '../components/FullPageSpinner';
import FullPageError from '../components/FullPageError';
import * as authClient from '../api/AuthClient';

const userPermissionsMap = {
  Admin: {
    Project: {
      View: true,
      Create: true,
      Modify: true,
      Share: true,
      Archive: true,
      Unarchive: true,
      ApproveNewUsers: true,
    },
    Dashboard: {
      View: true,
      Create: true,
      Modify: true,
      Share: true,
      Archive: true,
      Unarchive: true,
      ApproveNewUsers: true,
    },
  },
  Editor: {
    Project: {
      View: true,
      Create: true,
      Modify: true,
      Share: true,
      Archive: true,
      Unarchive: false,
      ApproveNewUsers: false,
    },
    Dashboard: {
      View: true,
      Create: true,
      Modify: true,
      Share: true,
      Archive: true,
      Unarchive: false,
      ApproveNewUsers: false,
    },
  },
  Viewer: {
    Project: {
      View: true,
      Create: false,
      Modify: false,
      Share: false,
      Archive: false,
      Unarchive: false,
      ApproveNewUsers: false,
    },
    Dashboard: {
      View: true,
      Create: false,
      Modify: false,
      Share: false,
      Archive: false,
      Unarchive: false,
      ApproveNewUsers: false,
    },
  },
};

// contains user information and methods to handle login/logout/register
const AuthContext = React.createContext();
AuthContext.displayName = 'AuthContext';

function AuthProvider(props) {
  const {
    data: user,
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
  } = useAsync();

  // This is what is called on application bootstrap
  useLayoutEffect(() => {
    run(authClient.getUser());
  }, [run]);

  const login = form => authClient.login(form).then(data => setData(data));

  const register = form => authClient.register(form).then(data => setData(data));

  const logout = () => {
    authClient.logout();
    setData(null);
  };

  const hasAccess = (type, permission) =>
    // Hack! All users are Admins by default
    userPermissionsMap[user?.type ?? 'Admin'][type][permission];

  const isSuperuser = user?.isSuperuser ?? false;

  const value = { user, login, logout, register, hasAccess, isSuperuser };

  if (isLoading || isIdle) {
    return <FullPageSpinner />;
  }

  if (isError) {
    return <FullPageError error={error} />;
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />;
  }

  throw new Error(`Unhandled status: ${status}`);
}

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
