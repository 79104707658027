import React from 'react';
import { css } from 'styled-components/macro';

function FullPageError({ error }) {
  return (
    <div
      css={css`
        color: danger;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      {error.status && <h2>{error.status}</h2>}
      <p>Uh oh... There&apos;s a problem. Try refreshing the browser.</p>
      <pre>{error.message}</pre>
    </div>
  );
}

export default FullPageError;
