// vendor imports
import React, { Suspense, lazy } from 'react';
import { toast } from 'react-toastify';

// imports
import FullPageSpinner from './components/FullPageSpinner';
import { useAuth } from './context';

// style imports
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// lazy imports
const AuthenticatedApp = lazy(() => import(/* webpackPrefetch: true */ './AuthenticatedApp'));
const UnauthenticatedApp = lazy(() => import('./UnauthenticatedApp'));

toast.configure({ hideProgressBar: true });

function App() {
  const { user } = useAuth();

  return (
    <Suspense fallback={<FullPageSpinner />}>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      <div className="tooltip" />
    </Suspense>
  );
}

export default App;
