import apiClient from './ApiClient';
import { LOCAL_STORAGE_KEY } from './ApiConf';

function handleUserResponse({ token, ...user }) {
  window.localStorage.setItem(LOCAL_STORAGE_KEY, token);
  return user;
}

function getToken() {
  return window.localStorage.getItem(LOCAL_STORAGE_KEY);
}

function isLoggedIn() {
  return Boolean(getToken());
}

function getUser() {
  const token = getToken();
  if (!token) {
    return Promise.resolve(null);
  }
  return apiClient('GET', 'users/me');
}

function login(body) {
  return apiClient('POST', 'users/auth', { body })
    .then(handleUserResponse)
    .then(() => {
      window.location.assign('/');
    });
}

function changePassword(body) {
  return apiClient('POST', 'users/changepassword', { body });
}

function requestAccount(body) {
  // TODO: needs to be implemented
  return apiClient('POST', 'users/account-request', { body });
}

function resetPassword(body) {
  return apiClient('POST', 'users/resetpassword', { body });
}

function register(body) {
  return apiClient('POST', 'register', { body }).then(handleUserResponse);
}

export {
  login,
  register,
  getToken,
  getUser,
  isLoggedIn,
  changePassword,
  requestAccount,
  resetPassword,
};
export { logout } from './ApiClient';
